'use client'

import { useEffect } from 'react'
import { getCookie } from 'cookies-next'
import { waitForAnalyticsLoad, identifyEvent } from '@acko-tech/building-blocks.ui.acko-analytics'

const useIdentifyEvent = () => {
  // we call identify even t and pass properties as {} because we are not updating user properties
  // this is needed to be done because of the way segment works i.e.
  // for other events like track or view segments internally sends user_id
  // to send that user_id we need to call identifyEvent
  const isLoggedIn: boolean = typeof getCookie('user_id') === 'string'
  useEffect(() => {
    if (isLoggedIn) {
      waitForAnalyticsLoad()
        .then(() => {
          identifyEvent({})
            .then(() => {})
            .catch(err => {
              console.log('identifyEvent error', err)
            })
        })
        .catch(err => {
          console.log('waitForAnalyticsLoad error', err)
        })
    }
  }, [])
}

export default useIdentifyEvent
