import { Lob } from 'src/enums/Lob'

export const segmentEvents = {
  sem: {
    pageView: 'view_sem_page',
    widgetType: 'SEM revamp widget',
  },
  seo: {
    pageView: 'view_seo_page',
    widgetType: 'SEO revamp widget',
  },
}

export const lobSegmentEvents = {
  sem: {
    [Lob.CAR]: {
      pageView: 'view_landing_page',
    },
    [Lob.BIKE]: {
      pageView: 'view_landing_page',
    },
  },
  seo: {
    [Lob.CAR]: {
      pageView: 'view_seo_page',
      widgetType: 'SEO revamp widget',
    },
    [Lob.BIKE]: {
      pageView: 'view_seo_page',
      widgetType: 'SEO revamp widget',
    },
  },
}

export const homePageFunnel = {
  [Lob.BIKE]: 'visit',
  [Lob.CAR]: 'visit',
  [Lob.HEALTH]: 'entry',
  [Lob.TRAVEL]: 'visit',
  [Lob.LIFE]: 'entry',
}
