'use client'

import { useState } from 'react'
import Image from 'next/image'
import { SUCCESS } from '@constants/toast'
import styles from './toast.module.scss'

import type { ToastProps } from './interface'
import { ERROR_ICON, SUCCESS_ICON } from './assetConstant'

const Toast = ({ message, isMobile, toastType, positionUp }: ToastProps) => {
  const [toastVisible, setToastVisible] = useState(true)

  if (!toastVisible || !message) {
    return null
  }
  return (
    <div className={styles.toastRoot}>
      <div
        className={`${styles.toastWrapper} ${isMobile ? styles.isMobile : ''} ${positionUp ? styles.positionUp : ''}`}>
        <div className={styles.contentWrapper}>
          {toastType && (
            <div onClick={() => setToastVisible(false)} className={styles.imageContainer}>
              <Image src={toastType === SUCCESS ? SUCCESS_ICON : ERROR_ICON} alt="success" height={16} width={16} />
            </div>
          )}
          <p className={styles.message}>{message}</p>
        </div>
      </div>
    </div>
  )
}

export default Toast
