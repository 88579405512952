/* eslint-disable @typescript-eslint/no-floating-promises */

'use client'

import { trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { homePageCommonProps } from '@utils/segmentProperties'
import React from 'react'
import { getUrlWithQueryParams } from '@utils/queryParamUtils'
import styles from './button.module.scss'
import type { ButtonType } from './buttonType'
import ButtonLoader from './loader'

export interface IButtonProps {
  text: string
  link?: string
  onButtonClick?: any
  buttonType?: ButtonType
  segmentEventName?: string
  segmentEventProps?: object
  disabled?: boolean | false
  additionalStyles?: string
}
const PrimaryButton = ({
  text,
  link,
  onButtonClick,
  buttonType,
  segmentEventName,
  segmentEventProps = {},
  disabled,
  additionalStyles
}: IButtonProps) => {
  const onClick = async () => {
    if (segmentEventName) {
      await trackEvent(segmentEventName, { ...homePageCommonProps(), ...segmentEventProps })
    }
    if (onButtonClick) {
      onButtonClick()
    } else if (link) {
      window.location.href = getUrlWithQueryParams(link) || ''
    }
  }
  return (
    <button
      className={`${styles.ctaButton} ${buttonType ? styles[buttonType] : ''} ${disabled ? styles.disabled : ''} ${additionalStyles}`}
      type="button"
      disabled={disabled}
      onClick={() => {
        onClick()
      }}>
      <span className={styles.ctaText}>{disabled ? <ButtonLoader /> : text}</span>
    </button>
  )
}

export default PrimaryButton
