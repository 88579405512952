/* eslint-disable @typescript-eslint/no-floating-promises */

'use client'

import { useEffect } from 'react'
import useIdentifyEvent from '@hooks/useIdentityEvent'
import { pageEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { getDevicePlatform } from '@utils/segmentProperties'
import { waitForAnalyticsLoad } from '@components/utils/analyticsViewPage'
import { r2d2 } from '@utils/utils'
import { getOsAndBrowser } from '@utils/deviceUtil'

interface PageLoadEventProps {
  viewEventName: string
  properties?: object
  propertiesFunc?: Function
  ekind?: string
}

const PageLoadEvent = ({ viewEventName, properties = {}, propertiesFunc, ekind }: PageLoadEventProps) => {
  useIdentifyEvent()
  useEffect(() => {
    const allProperties = { ...properties, ...getOsAndBrowser() }
    ;(async () => {
      waitForAnalyticsLoad()
        .then(() => {
          pageEvent(viewEventName, {
            platform: getDevicePlatform(),
            ...allProperties,
            ...(propertiesFunc ? propertiesFunc() : {}),
          })
        })
        .catch(err => {
          console.log('Wait for analytics load error', err)
        })
      if (ekind) {
        r2d2(
          {
            ekind,
            okind: 'tracker',
          },
          `${window?.location?.origin}/api/r2d2/`
        )
      }
    })()
  }, [])

  return null
}

export default PageLoadEvent
