import React from 'react'
import styles from './button.module.scss'

const ButtonLoader = () => (
  <div className={styles.loaderRootContainer}>
    <div className={`${styles.loaderDot} ${styles.delay_0s}`} />
    <div className={`${styles.loaderDot} ${styles.delay_0_1s}`} />
    <div className={`${styles.loaderDot} ${styles.delay_0_2s}`} />
  </div>
)

export default ButtonLoader
