import { Browser } from '@enums/Browser'
import { OperatingSystem } from '@enums/OperatingSystem'

export const detectFromUserAgent = enumObject => {
  const userAgent = navigator?.userAgent
  const detectedEntry = Object.entries(enumObject).find(([key]) => userAgent?.includes(key))
  return detectedEntry ? detectedEntry[1] : null
}

export function getOsAndBrowser() {
  const detectedBrowser = detectFromUserAgent(Browser)
  const detectedOS = detectFromUserAgent(OperatingSystem)

  return {
    ...(detectedOS ? { os: detectedOS } : {}),
    ...(detectedBrowser ? { browser: detectedBrowser } : {}),
  }
}
