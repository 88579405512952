/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */

'use client'

import { useEffect } from 'react'
import { getPageType } from 'src/helpers/commonHelpers'
import useIdentifyEvent from '@hooks/useIdentityEvent'
import { lobSegmentEventProperties, segmentEventCommonPropFunc } from '@utils/segmentProperties'
import { pageEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { lobSegmentEvents, segmentEvents } from '@configs/segmentEvents.config'

export function waitForAnalyticsLoad() {
  return new Promise(resolve => {
    const resolver = () => {
      if (window?.acko_analytics && window?.acko_analytics?.anonymousId !== undefined) {
        resolve(void 0)
      } else {
        window.setTimeout(resolver, 300)
      }
    }
    setTimeout(resolver, 300)
  })
}

const AnalyticsViewPage = ({ segmentEventCommonProperties, isSemPage = false, lob, reqCookie }) => {
  useIdentifyEvent()
  useEffect(() => {
    ;(async () => {
      const properties = await segmentEventCommonPropFunc(segmentEventCommonProperties)
      const viewEventName =
        lobSegmentEvents?.[getPageType(isSemPage)]?.[lob]?.pageView || segmentEvents?.[getPageType(isSemPage)]?.pageView
      const widgetType =
        lobSegmentEvents?.[getPageType(isSemPage)]?.[lob]?.widgetType ||
        segmentEvents?.[getPageType(isSemPage)]?.widgetType

      const additionalLobEventProperties = lobSegmentEventProperties[lob]?.(reqCookie, isSemPage) || {}

      waitForAnalyticsLoad()
        .then(() => {
          console.log(window?.acko_analytics?.anonymousId, isSemPage, ':::Analytics changes')
          pageEvent(viewEventName, {
            ...properties,
            ...additionalLobEventProperties,
            widget_type: widgetType,
            funnel: 'visit',
          })
        })
        .catch(err => {
          console.log('Wait for analytics load error', err)
        })
    })()
  }, [])

  return null
}

export default AnalyticsViewPage
