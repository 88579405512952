export const FETCH_REWARDS_ENDPOINT = '/rewards/api/v1/fetch'
export const FETCH_USER_ID = '/api/user/get/'
export const OAUTH_GENERATE_TOKEN_ENDPOINT = '/token'
export const BEARER_TOKEN_KEY = 'bearerToken'
export const SUCCESS_MESSAGE = 'We’ve sent the app download link to your mobile number'
export const ERROR_MESSAGE = 'Error! Please try again Later'
export const APP_LINK_GENERIC = 'https://ackoapp.onelink.me/TnnH/geb0pi1v'
export const COUPON_FALL_BACK_VALUE = {
  appLink:
    'https://www.acko.com/gi/lp/new-car-comprehensive-hi?utm_source=coupons_atl&utm_medium=coupons_atl&utm_campaign=coupons_atl',
  productImage: 'https://acko-cms.ackoassets.com/Car_Sedan_Light_5ca5ffe0ae.png',
  productName: 'product name',
}
export const REWARDS_LOGIN = (phoneNumber = '') => `/login?next=%2Fmyrewards${phoneNumber && `&mobile=${phoneNumber}`}`
