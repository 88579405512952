import { TOAST_TIME } from '@constants/toast'

interface DoToastProps {
  message: string
  type: string
  setToastText: Function
  setToastType: Function
}

export const doToast = ({ message, type, setToastText, setToastType }: DoToastProps) => {
  setToastText(message)
  setToastType(type)
  setTimeout(() => {
    setToastText('')
  }, TOAST_TIME)
}
