export const ERROR_MESSAGE = 'Error! Please try again Later'
export const TOAST_TIME = 5000
export const SUCCESS_MESSAGE = 'We’ve sent the app download link to your mobile number'
export const DUPLICATE_TRY = 'We’ve already sent the app download link to your mobile number'

export const SUCCESS = 'success'
export const ERROR = 'error'
export const FEATURED = 'Featured'
export const TRANDING = 'Trending now @'
export const PLATFORM = 'web'
export const SEE_ALL = 'See all'
export const arrowLink = 'https://acko-cms.ackoassets.com/arrow_e4d8e63339.svg'
