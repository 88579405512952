'use client'

import { useEffect, useRef, useState } from 'react'
import { getConfig } from '@configs/evnConfig'
import { GET_HCAPTCHA_TOKEN_EVENT, LOAD_HCAPTCHA_EVENT } from '@constants/customEvents'
import { useEnvVariablesClientConfig } from '@context/EnvClientContext/EnvVariablesClientContext'
import HCaptcha from '@hcaptcha/react-hcaptcha'

const HCaptchaWrapper = ({ showOnMount = false }) => {
  const [token, setToken] = useState<string>('')
  const [visible, setVisible] = useState(showOnMount)
  const captchaRef = useRef<HCaptcha>(null)

  useEffect(() => {
    document.addEventListener(LOAD_HCAPTCHA_EVENT, handleLoadHCaptcha)
    return () => {
      document.removeEventListener(LOAD_HCAPTCHA_EVENT, handleLoadHCaptcha)
    }
  }, [])
  useEffect(() => {
    if (token) {
      const tokenValue = token
      const tokenEvent = new CustomEvent(GET_HCAPTCHA_TOKEN_EVENT, {
        detail: { token: tokenValue },
      })

      document.dispatchEvent(tokenEvent)
    }
  }, [token])

  const handleLoadHCaptcha = () => {
    setVisible(true)
    captchaRef.current?.execute()
  }

  const config = useEnvVariablesClientConfig()

  const currentEnv = config?.ACKO_ENV
  const HCAPTCHA_SITE_KEY = getConfig(currentEnv)?.hcaptchaSitekey

  const onLoad = () => {
    captchaRef.current?.execute()
  }

  return (
    visible && (
      <HCaptcha
        sitekey={HCAPTCHA_SITE_KEY || ''}
        size="invisible"
        onVerify={token => {
          token && setToken(token)
        }}
        onError={err => {
          setToken('')
          console.error('Hcaptcha token generation failed ', err)
        }}
        ref={captchaRef}
        onLoad={onLoad}
      />
    )
  )
}

export default HCaptchaWrapper
